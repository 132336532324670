export enum LocalStorageKey {
  CookiePreferences = 'instatab-website/User/CookieConsentPreferences',
}

// https://flatuicolors.com/palette/cn
export const palette = {
  body: '#fff8f5',
  themeLightGray: '#f9f9f7',
  themePrimary: '#ff7f50',
  themeSecondary: '#ff6348',
  themeTertiary: '#cc4f3a',
  black: '#161412',
  'brand.50': '#fff4ed',
  'brand.100': '#ffe5d4',
  'brand.200': '#ffc7a8',
  'brand.300': '#ffa071',
  'brand.500': '#ff7f50',
  'brand.600': '#fe4711',
  'brand.700': '#c61c08',
  'brand.800': '#9d190f',
};

// default Chakra UI breakpoints: https://chakra-ui.com/docs/styled-system/responsive-styles
export const breakpoints = {
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
};

export const gaps = {
  section: {
    base: 8,
    lg: 10,
  },
};

export const SUPPORT_EMAIL = 'support@instatab.io';

export const CONTAINER_SIZE = '6xl';

export const NAVBAR_HEIGHT = 60;

export const routes = {
  index: (anchor?: string) => `/${anchor ? `#${anchor}` : ''}`,
  blog: (slug?: string) => `/blog${slug ? `/${slug}` : ''}`,
  termsOfUse: () => '/terms-of-use',
  privacyPolicy: () => '/privacy-policy',
  faq: (slug?: string) => `/faq${slug ? `/${slug}` : ''}`,
  legalNotice: () => '/legal-notice',
};

export const MailchimpConfig = {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  postUrl: `${process.env.GATSBY_MAILCHIMP_POST_URL}?u=${process.env.GATSBY_MAILCHIMP_U}&id=${process.env.GATSBY_MAILCHIMP_ID}`,
};

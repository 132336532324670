import { extendTheme } from '@chakra-ui/react';
import { palette } from '../../core/constants';

const variantOutlined = () => ({
  field: {
    _focus: {
      borderColor: 'var(--chakra-ui-focus-ring-color)',
      boxShadow: '0 0 0 2px var(--chakra-ui-focus-ring-color)',
    },
  },
});

const variantFilled = () => ({
  field: {
    _focus: {
      borderColor: 'var(--chakra-ui-focus-ring-color)',
      boxShadow: '0 0 0 1px var(--chakra-ui-focus-ring-color)',
    },
  },
});

const variantFlushed = () => ({
  field: {
    _focus: {
      borderColor: 'var(--chakra-ui-focus-ring-color)',
      boxShadow: '0 1px 0 0 var(--chakra-ui-focus-ring-color)',
    },
  },
});

export const theme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  styles: {
    global: {
      // Create a CSS variable with the focus ring color desired.
      // rgba function does not work here so use the hex value.
      // Either :host,:root or html work. body does not work for
      // button, checkbox, radio, switch.
      // html: {
      ':host,:root': {
        '--chakra-ui-focus-ring-color': palette.themePrimary,
      },
    },
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: 700,
        fontSize: { base: '3xl', sm: '4xl', md: '6xl' },
        lineHeight: '110%',
      },
    },
    Input: {
      variants: {
        outline: variantOutlined,
        filled: variantFilled,
        flushed: variantFlushed,
      },
    },
    Select: {
      variants: {
        outline: variantOutlined,
        filled: variantFilled,
        flushed: variantFlushed,
      },
    },
    Textarea: {
      variants: {
        outline: () => variantOutlined().field,
        filled: () => variantFilled().field,
        flushed: () => variantFlushed().field,
      },
    },
  },
  colors: {
    black: '#0a0a0a',
    brand: {
      50: palette['brand.50'],
      100: palette['brand.100'],
      200: palette['brand.200'],
      300: palette['brand.300'],
      // 400: palette['brand.400'],
      500: palette['brand.500'],
      600: palette['brand.600'],
      700: palette['brand.700'],
      800: palette['brand.800'],
    },
  },
  fonts: {
    heading: `'Nunito', sans-serif`,
    body: `'Nunito', sans-serif`,
    button: `'Nunito', sans-serif`,
  },
  shadows: {
    outline: `0 0 0 3px ${palette.themePrimary}`,
  },
});

export default extendTheme(theme);
